

























































































































import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import FormDrawer from '@/components/common/FormDrawer/index.vue'
import _ from 'lodash'
import { ElForm } from 'element-ui/types/form'
import { area } from '@/utils/options'
import { is } from '@/utils/helpers'

const initializeData = (): {
  name: string
  parentId: string
  level: number
  addressType: number
  standardAddress: AnyObj
} => ({
  name: '',
  parentId: '',
  level: 0,
  addressType: 1,
  standardAddress: {}
})

@Component({ components: { FormDrawer } })
export default class AreaDrawer extends Vue {
  @Prop() visible: boolean
  @Prop({ default: 'create' }) mode: 'create' | 'update'
  @Prop({ default: () => ({}) }) data?: AnyObj
  @Prop() handleSubmit?: (formData: AnyObj) => void

  @Ref('form') form: ElForm

  get areaTypes() {
    return area.level
  }

  get addressTypes() {
    return area.addressTypes
  }

  get isCreateMode() {
    return this.mode === 'create'
  }

  get title() {
    return this.isCreateMode ? '新建区域' : '编辑区域'
  }

  formData = initializeData()
  rules = {
    name: [{ required: true, message: '请输入区域名称', trigger: 'blur' }],
    parentId: [{ required: true, message: '请选择所属区域', trigger: 'blur' }],
    level: [{ required: true, message: '请选择区域类型', trigger: 'blur' }],
    addressType: [{ required: true, message: '请选择地址类型', trigger: 'blur' }]
  }
  loading = false

  mounted() {
    this.initialize()
  }

  initialize() {
    if (this.data) {
      const formData = _.pickBy(
        _.pick(this.data, [
          'name',
          'parentId',
          'description',
          'level',
          'standardAddress',
          'addressType'
        ]),
        is.ava
      )

      this.formData = { ...this.formData, ...formData }
    }
  }

  close() {
    this.formData = initializeData()
    this.form.resetFields()
    this.visible = false
    this.$emit('close')
  }

  submit() {
    this.form.validate(async valid => {
      if (valid) {
        this.loading = true

        if (this.handleSubmit) {
          await this.handleSubmit({ ...this.formData })
        }

        this.loading = false
        this.close()
      }
    })
  }
}
