




























































import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import InputSearch from '@/components/common/InputSearch.vue'
import AreaDrawer from './AreaDrawer.vue'
import { ElTree } from 'element-ui/types/tree'
import _ from 'lodash'
import { is } from '@/utils/helpers'
import Modal from '@/components/common/Modal'

const drawer = Modal(AreaDrawer)

@Component({ components: { InputSearch } })
export default class Areas extends Vue {
  @Prop({ type: Boolean, default: false }) readonly: boolean
  @Prop() showCount: Function

  @Ref() areaTree: ElTree<string, AnyObj>

  filterText = ''
  expandedKeys: string[] = []
  currentNodeKey = ''
  loading = false

  get state() {
    return this.$store.state.areas
  }

  get areas() {
    return this.$store.state.areas.areas
  }

  @Watch('areas')
  watchAreas() {
    this.$nextTick(() => {
      this.areaTree.setCurrentKey(this.currentNodeKey)
      this.areaTree.search(this.filterText)
    })
  }

  async created() {
    if (this.areas.length === 0) {
      await this.fetch()
    }

    this.$nextTick(() => {
      this.expandedKeys = [this.areas[0].id]
      const [root] = this.state.areas

      if (root) {
        this.handleAreaChange(root)
      }
    })
  }
  private getTreeLabel(data: AnyObj) {
    const label = data.name
    const index = label.indexOf(this.filterText)

    if (index > -1) {
      const before = label.substr(0, index)
      const after = label.substr(index + this.filterText.length)

      return { before, after }
    }
  }

  async fetch() {
    this.loading = true

    await this.$store.dispatch('areas/fetchAreas')

    this.loading = false
  }

  search(value: string) {
    this.filterText = value
    this.areaTree.search(value)
  }

  handleAreaChange(area: AnyObj) {
    this.currentNodeKey = area.id
    this.areaTree.setCurrentKey(area.id)
    this.$emit('area-change', area)
  }

  handleNodeExpand(data: any) {
    if (!this.expandedKeys.find(item => item === data.id)) {
      this.expandedKeys.push(data.id)
    }
  }
  handleNodeCollapse(data: any) {
    this.expandedKeys = this.expandedKeys.filter(item => item !== data.id)
  }

  addArea() {
    drawer.open({
      mode: 'create',
      data: { parentId: this.areaTree.getCurrentKey() },
      handleSubmit: async (formData: AnyObj) => {
        const { data } = await this.$api.europa.createArea({
          ..._.pickBy(formData, is.ava)
        })

        if (data.code === 0) {
          await this.fetch()
        }
      }
    })
  }

  editArea(area: AnyObj) {
    drawer.open({
      mode: 'update',
      data: area,
      handleSubmit: async (formData: AnyObj) => {
        delete formData.level
        const { data } = await this.$api.europa.updateArea(area.id, {
          ..._.mapValues(formData, v => (is.ava(v) ? v : null))
        })

        if (data.code === 0) {
          await this.fetch()
        }
      }
    })
  }

  deleteArea(area: AnyObj) {
    this.$confirm('区域一经删除无法恢复，基于区域的统计数据也将删除，是否确定删除？', '提示', {
      type: 'warning',
      callback: async action => {
        if (action === 'confirm') {
          await this.$api.europa.deleteArea(area.id).then(() => this.fetch())

          if (area.id === this.currentNodeKey) {
            const [root] = this.areas

            if (root) {
              this.areaTree.setCurrentKey(root.id)
              this.$emit('area-change', root)
            }
          }
        }
      }
    })
  }
}
